import { createTheme } from "@mui/material/styles";

const BaseTheme  = createTheme({
    palette:{
        primary:{
            main:"#E89B0E",
            hover:"#EFA51D",
            bgColor:"#E89B0E",
        },
        secondary:{
            main:"#A081DE",
            hover:"#7C4ED8",
            bgColor:"#7C4ED8",
            green:"#129D5A",
            purpal:"#7C4ED8",

        },
        common:{
            grey:"#646464",
            black:"#000000",
        },
        typography:{
            fontFamily:"Roboto",
            fontSize:"20px !important",
        },
    }

})

export default BaseTheme;