import { Box, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import SayHelloButton from "./SayHelloButton";
import ConnectImg from "../asset/images/connect.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const ConnectCard = () => {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} sx={{ alignItems:"center"}}>
        <Grid item xs={12} sm={7} md={8} lg={8} xl={9}>
          <Typography
            color="black"
            sx={{ fontSize:{lg:40, md:36, xs:26}  , fontWeight: "bold" }}
          >
            <span
              style={{
                paddingBottom:"8px",
                borderBottom: "4px solid black",
              }}
            >
              @C
            </span>
            onnect
          </Typography>
          <Typography
            color="black"
            my={3}
            sx={{fontSize:{lg:36, md:26, xs:22}, fontWeight: "bold" }}
          >
            We make Conversation Matter....
          </Typography>

          <Box sx={{marginTop:"10px", display:{xs:"none", sm:"block"}}}>
            <SayHelloButton hello={"Say Hello"} />
          </Box>
        </Grid>

        <Grid item xs={12} sm={5} md={4} lg={4} xl={3} sx={{display:{xs:"none", sm:"block"}}}>
          <Box textAlign={"end"} >
          <img src={ConnectImg} alt="contact" width={"100%"}/>
          </Box>
          <Box sx={{marginTop:"20px", display:{xs:"block", sm:"none"}, textAlign:"center"}}>
            <SayHelloButton hello={"Say Hello"} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConnectCard;
