import React from "react";
import Layout from "../component/Layout";

const Pagenotfound = () => {
  return (
    <>
    <Layout>
      <h1>Pagenotfound</h1>
    </Layout>
  </>
  );
};

export default Pagenotfound;
