import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
  } from "@mui/material";
  import React from "react";
  import vectorImg from "../asset/images/Vector.png";


const ValueCard = () => {
  return (
    <div>
     <Box
       sx={{
         backgroundImage: `url(${vectorImg})`,
         backgroundSize: {xl:"cover", lg:" cover  95% 98%", md: "100% 1000px" },
         backgroundPosition: "center",
         backgroundRepeat:"no-repeat",
        pt:2,
        mb:10,
         display:{xs:"none", lg:"block"}
       }}
     >
       <Grid
         container
         width="100%"
         height="300px"
         justifyContent="space-between"
       >
         <Grid
           item
           lg={3}
           sx={{
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
             pb: 9,
           }}
         >
           <Card
             variant="outlined"
             sx={{
               border: "1px solid orange",
               borderRadius: 10,
               width: "300px",
             }}
           >
             <CardContent>
               <Typography variant="h5" fontWeight={500}>
                 Innovation
               </Typography>
               <Typography variant="body1">
                 Embracing cutting-edge technologies and methodologies.
               </Typography>
             </CardContent>
           </Card>
         </Grid>
         <Grid
           item
           lg={3}
           sx={{
             display: "flex",
             justifyContent: "flex-end",
             alignItems: "flex-end",
             pb: 3,
           }}
         >
           <Card
             variant="outlined"
             sx={{
               border: "1px solid orange",
               borderRadius: 10,
               width: "300px",
             }}
           >
             <CardContent>
               <Typography variant="h5" fontWeight={500}>
                 Excellence
               </Typography>
               <Typography variant="body1">
                 Striving for the highest standards of quality in training
                 delivery.
               </Typography>
             </CardContent>
           </Card>
         </Grid>
         <Grid item lg={3}>
           <Card
             variant="outlined"
             sx={{
               border: "1px solid orange",
               borderRadius: 10,
               width: "300px",
             }}
           >
             <CardContent>
               <Typography variant="h5" fontWeight={500}>
                 Empowerment
               </Typography>
               <Typography variant="body1">
                 Equipping individuals with skills to succeed in a rapidly
                 evolving industry.
               </Typography>
             </CardContent>
           </Card>
         </Grid>
       </Grid>
       <Grid
         container
         width={"100%"}
         height={"300px"}
         py={2}
         justifyContent={"space-evenly"}
       >
         <Grid item lg={4}>
           <Card
             variant="outlined"
             sx={{
               border: "1px solid orange",
               borderRadius: 10,
               width: "300px",
             }}
           >
             <CardContent>
               {" "}
               <Typography fontSize={22} fontWeight={500}>
                 Adaptability
               </Typography>
               <Typography fontSize={16}>
                 Staying flexible and responsive to changes in technology and
                 market demands.​
               </Typography>
             </CardContent>
           </Card>
         </Grid>
         <Grid item lg={3}>
           <Card
             variant="outlined"
             sx={{
               border: "1px solid orange",
               borderRadius: 10,
               width: "300px",
             }}
           >
             <CardContent>
               {" "}
               <Typography fontSize={22} fontWeight={500}>
                 Impact
               </Typography>
               <Typography fontSize={16}>
                 Making a positive difference in the lives and careers of
                 individuals through training.​
               </Typography>
             </CardContent>
           </Card>
         </Grid>
       </Grid>
       <Grid
         container
         width={"100%"}
         height={"300px"}
         justifyContent={"center"}
       >
         <Grid item lg={2}>
           <Card
             variant="outlined"
             sx={{
               border: "1px solid orange",
               borderRadius: 10,
               width: "300px",
             }}
           >
             <CardContent>
               {" "}
               <Typography fontSize={22} fontWeight={500}>
                 Integrity
               </Typography>
               <Typography fontSize={16}>
                 Upholding honesty, ethics, and transparency in all
                 interactions.
               </Typography>
             </CardContent>
           </Card>
         </Grid>
         <Grid
           item
           lg={3}
           sx={{
             display: "flex",
             justifyContent: "flex-end",
             alignItems: "flex-end",
             pb: 2,
           }}
         >
           <Card
             variant="outlined"
             sx={{
               border: "1px solid orange",
               borderRadius: 10,
               width: "300px",
             }}
           >
             <CardContent>
               {" "}
               <Typography fontSize={22} fontWeight={500}>
                 Continuous Learning
               </Typography>
               <Typography fontSize={16}>
                 Promoting a culture of lifelong learning and skill
                 development.
               </Typography>
             </CardContent>
           </Card>
         </Grid>
       </Grid>
     </Box>
  </div>
  )
}

export default ValueCard