import React from "react";
import Layout from "../component/Layout";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import GetButton from "../component/GetButton";
import { Box, Container } from "@mui/system";
import CardHover from "../component/CardHover";
import { inculsifyHover, skillAugmentation } from "../data/home/standCard";
import CollapseCards from "../component/CollapseCards";
import StarPurple500Icon from "@mui/icons-material/StarPurple500";
import CollapseAcademyCard from "../component/CollapseAcademyCard";
import {
  CollAcaData,
  CorporateCollapse,
  SofwareCollapse,
  frontiersCollapse,
} from "../data/CollapseAcaData";
import SayHelloButton from "../component/SayHelloButton";
import AcademyCard from "../component/AcademyCard";
import { AutoAcademy, CorporateComp, SofwareSkills } from "../data/AcademyData";
import UspAccordian from "../component/UspAccordian";

const data = [
  "Experience a well-rounded approach to learning, seamlessly blending robotics and software expertise.",
  "Get top-notch guidance from experienced professionals who are here to mentor your every step of the way.",
  "Explore the perfect mix of hardware, coding, and essential soft skills to nurture your professional growth.",
  "Dive into real-world projects and practical applications that pave the path for your future learning journey.",
  "Our curriculum is designed to develop skills that directly translate to success and growth in your chosen field.",
];

const InculsifyHub = () => {
  return (
    <>
      <Layout>
        <Container
          sx={{
            maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
          }}
        >
          <Box mt={5}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  mb={5}
                  sx={{
                    fontWeight: "bold",
                    lineHeight: { lg: "65px", md: "46px" },
                    marginTop: { xl: 8, md: 0 },
                    fontSize: { lg: 60, md: 40, xs: 30 },
                    textAlign: { md: "left", xs: "center" },
                  }}
                >
                  <span
                    style={{
                      borderBottom: "4px solid black",
                      paddingBottom: "8px",
                    }}
                  >
                    Incl
                  </span>
                  usive{" "}
                  <strong style={{ color: "#129D5A" }}>Development</strong>
                </Typography>

                <Typography
                  paragraph={true}
                  color="grey"
                  lineHeight="30px"
                  align="justify"
                  sx={{
                    textAlign: { md: "justify", xs: "center" },
                    fontSize: { lg: 20, md: 18, xs: 16 },
                  }}
                >
                  Holistic development prioritizes nurturing every aspect of an
                  individual's life to foster overall growth and fulfillment.
                  This approach aims to achieve balance and harmony across
                  multiple areas. It involves offering opportunities for
                  personal growth, building meaningful connections, promoting
                  well-being, fostering creativity and self-expression, and
                  instilling values like empathy, compassion, and resilience.
                  The goal is to empower individuals to become well-rounded,
                  self-aware individuals capable of thriving in every aspect of
                  life.
                </Typography>

                <List>
                  {data &&
                    data.map((item, index) => (
                      <>
                        <ListItem disablePadding alignItems="start">
                          <ListItemIcon>
                            <StarPurple500Icon
                              size="large"
                              sx={{ color: "black" }}
                            />
                          </ListItemIcon>
                          <Typography
                            sx={{ fontSize: { md: 18, xs: 16 } }}
                            color="grey"
                          >
                            {item}
                          </Typography>
                        </ListItem>
                      </>
                    ))}
                </List>
                <Box
                  sx={{
                    marginTop: { md: 4, lg: 5 },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <GetButton hello1={"Say Hello"} />
                </Box>
              </Grid>
            </Grid>
          </Box>{" "}
          <Box sx={{ flexGrow: 1 }}>
            <Grid xs={12} mt={5}>
              <Typography
                color="black"
                mb={5}
                sx={{
                  fontSize: { lg: 40, md: 32, xs: 24 },
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    borderBottom: "4px solid black",
                    paddingBottom: "8px",
                  }}
                >
                  Skil
                </span>
                l Augmentation​
              </Typography>
              <Typography
                paragraph={true}
                color="grey"
                lineHeight="30px"
                align="justify"
                fontSize="18px"
                mb={5}
              >
                Skill augmentation isn't just about learning the same old way.
                It's about creating an environment where you not only gain
                knowledge but also see a real improvement in your practical
                skills.​
              </Typography>
            </Grid>
            <Grid container spacing={3}>
              {skillAugmentation.map((item) => (
                <CardHover
                  title={item.number}
                  heighlight={item.heighlight}
                  extraText={item.textextra}
                />
              ))}
            </Grid>
          </Box>
          {/* ---------1 satrt--------- */}
          <Box sx={{ flexGrow: 1 }} mt={6}>
            <Grid>
              <Typography
                color="primary"
                sx={{ fontSize: { lg: 20, md: 18, xs: 16 } }}
              >
                Explore Our Offrings
              </Typography>
              <Typography
                color="black"
                mb={4}
                sx={{
                  fontSize: { lg: 40, md: 32, xs: 24 },
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    borderBottom: "4px solid black",
                    paddingBottom: "8px",
                  }}
                >
                  Robo
                </span>
                tics Skills
              </Typography>
            </Grid>

            <AcademyCard data={AutoAcademy} />
          </Box>
          <Box>
            <CollapseAcademyCard data={CollAcaData} />
          </Box>
          {/* ---------1 end--------- */}
          {/* ---------2 satrt--------- */}
          <Box sx={{ flexGrow: 1 }} mt={6}>
            <Grid>
              <Typography
                color="black"
                mb={4}
                sx={{
                  fontSize: { lg: 40, md: 32, xs: 24 },
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    borderBottom: "4px solid black",
                    paddingBottom: "8px",
                  }}
                >
                  Soft
                </span>
                ware Skills
              </Typography>
            </Grid>

            <AcademyCard data={SofwareSkills} />
          </Box>
          <Box>
            <CollapseAcademyCard data={SofwareCollapse} />
          </Box>
          {/* ---------2 end--------- */}
          {/* ---------3 satrt--------- */}
          <Box sx={{ flexGrow: 1 }} mt={6}>
            <Grid>
              <Typography
                color="black"
                mb={4}
                sx={{
                  fontSize: { lg: 40, md: 32, xs: 24 },
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    borderBottom: "4px solid black",
                    paddingBottom: "8px",
                  }}
                >
                  Corp
                </span>
                orate Competence
              </Typography>
            </Grid>

            <AcademyCard data={CorporateComp} />
          </Box>
          <Box>
            <CollapseAcademyCard data={CorporateCollapse} />
          </Box>
          {/* ---------3 end--------- */}
          <Box sx={{ flexGrow: 1 }} mt={6}>
            <UspAccordian />
          </Box>
          {/* <Box flex={{ flexGrow: 1 }}>
            <Grid xs={12} mt={5}>
              <Typography
                color="black"
                mb={5}
                sx={{
                  fontSize: { lg: 40, md: 32, xs: 24 },
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    borderBottom: "4px solid black",
                    paddingBottom: "8px",
                  }}
                >
                  Fresh{" "}
                </span>
                Approch to Learning
              </Typography>
              <Typography
                paragraph={true}
                color="grey"
                lineHeight="30px"
                align="justify"
                fontSize="18px"
                mb={5}
              >
                The significance of essential soft skills, such as effective
                communication and teamwork, is often overlooked in technical
                education, despite their critical role in achieving success.
                Therefore, it is imperative that course content remains
                pertinent to industry demands, ensuring a seamless transition
                into the workforce. To achieve this, it is crucial to
                incorporate cutting-edge technologies like AI, ML, and IoT into
                practical training.
              </Typography>
            </Grid>

            <Grid container spacing={3}>
              {inculsifyHover.map((item) => (
                <CardHover
                  title={item.number}
                  text={item.text}
                  heighlight={item.heighlight}
                  extraText={item.textextra}
                />
              ))}
            </Grid>
          </Box> 

          <Box flex={{ flexGrow: 1 }} mt={10}>
            <Grid xs={12} mt={5}>
              <Typography
                color="black"
                mb={5}
                sx={{
                  fontSize: { lg: 40, md: 32, xs: 24 },
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    borderBottom: "4px solid black",
                    paddingBottom: "8px",
                  }}
                >
                  Our{" "}
                </span>
                Offerings
              </Typography>
              <Typography
                paragraph={true}
                color="grey"
                lineHeight="30px"
                align="justify"
                fontSize="18px"
                mb={5}
              >
                InAutomate is dedicated to shaping tomorrow's technical
                frontiers by empowering individuals with cutting-edge training
                skills. Our programs are meticulously designed to equip
                participants with the knowledge and expertise needed to innovate
                and lead in rapidly advancing fields. With a focus on the latest
                technologies and industry trends, we aim to cultivate a
                community of forward-thinkers who will drive progress and make a
                meaningful impact in the ever-evolving technological landscape.
              </Typography>
            </Grid>
            <CollapseAcademyCard data={frontiersCollapse} prop={true}/>
          </Box> */}
        </Container>
      </Layout>
    </>
  );
};

export default InculsifyHub;
