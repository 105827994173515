import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import iconImg from "../asset/images/BulletIcon.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import React, { useState } from "react";
import SayHelloButton from "./SayHelloButton";

const CollapseAcademyCard = ({ data, prop }) => {
  const displayHidden = prop;
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };
  return (
    <>
      <Grid container columnSpacing={5} mt={5}>
        <Grid item xs={12} md={12} lg={6}>
          <Box sx={{ marginBottom: { lg: "0", xs: "20px" } }}>
            <img src={data.image} width={"100%"} height={"auto"} />
          </Box>
        </Grid>

        <Grid item xs={12} md={12} lg={6} pt={2}>
        {data.items.map((item,index) => (
          <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
            sx={{
              border: "2px solid black",
              borderRadius: "25px !important",
              padding:{xl:"15px 10px 15px 10px", xs:"5px 10px 5px 10px"},
              marginBottom:{xl:"15px",xs:"10px"},
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowOutwardIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <img src={iconImg} alt="bullet" width="30px"/>
              <Typography
                sx={{ fontSize: {xl:22, lg: 20, xs: 18 } }}
                fontWeight="bold"
                px={2}
              >
                {item.title}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography color="grey" sx={{ fontSize: { lg: 18, xs: 16 },textAlign:"justify" }}>
                {item.des}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}

       {displayHidden ?
              <Box ml={1} mt={3}>
            <SayHelloButton learn={"Learn More"} />
            </Box>:""
        }   
        </Grid>
      </Grid>
    </>
  );
};

export default CollapseAcademyCard;
