import Img1 from "../asset/images/autoAcademy.png";
import Img2 from "../asset/images/dynamos1.png";
import Img3 from "../asset/images/corporate1.png";
import Img4 from "../asset/images/student.png";
import Img5 from "../asset/images/college.png";
import Img6 from "../asset/images/autoPerspective.png";
import expert from "../asset/images/expert.png";

export const AutoAcademy = {
  des: "Learn the fundamentals of robotics as well as advanced techniques with us to navigate the challenges and sieze opportunities.",
  items: [
    "InAutomate's comprehensive Robotic Skills training program are designed to prepare you for the future by providing you the fundamentals of robotics and advanced techniques.",
    "With hands-on experience using state-of-the-art equipment and simulations, you'll be ready for exciting opportunities in the field of robotics and automation.",
  ],
  image: Img1,
};

export const SofwareSkills = {
  des: "Our training programs provide a comprehensive and in-depth learning experience for individuals looking to acquire revolutionary software skills.",
  items: [
    "Our training programs offer the opportunity to acquire revolutionary software skills, tailored to meet the evolving demands of the tech industry.",
    "Participants will have the chance to explore AI-driven development, quantum computing, and low-code systems to remain ahead of the curve.",
  ],
  image: Img2,
};

export const CorporateComp = {
  des: "We offer comprehensive corporate competence training that is designed to help individuals and organizations improve their skills and knowledge in various areas.",
  items: [
    "Our tailored programs focus on strategic planning, effective leadership, and adaptable problem-solving, fostering resilience, innovation, and collaboration within your organization.",
    "Empower your workforce to navigate challenges and seize opportunities with confidence.",
  ],
  image: Img3,
};

export const collaborativeData = {
  des: "Our collaborative endeavor focuses on equipping technical students with essential skills through a dynamic partnership between expert trainers, industry practitioners, and dedicated technical support staff.",
  image: expert,
  details: [
    {
      heighText: "Mentorship Program",
      text: "Establishing mentorship programs to guide students in their career development.",
    },
    {
      heighText: "Certification Coordinators",
      text: "Coordinating certification programs to validate student skills and knowledge.",
    },
    {
      heighText: "Trainer Development Programs",
      text: "Offering programs to enhance the skills and effectiveness of trainers.",
    },
    {
      heighText: "Community Engagement",
      text: "Engaging with the community to promote collaboration and knowledge sharing.",
    },
  ],
};

export const expertiesData = [
  {
    heighlight: "Equi",
    heading: "pment Infrastructure",
    des: "Cutting-edge tech, specialized hardware, and IT support enrich curriculum through hands-on exercises for practical learning.",
    items: [
      {
        itemNo: "01",
        title: "State-of-the-Art Resources",
        points: [
          "Latest hardware and software tools",
          "Enhanced skill set and practical knowledge",
          "Facilitate immersive, hands-on learning experiences",
        ],
      },
      {
        itemNo: "02",
        title: "Specialized Automation",
        points: [
          "Prepared for real-world challenges",
          "Hardware tailored for training & experimentation",
          "Enable to delve into the intricacies of automation",
        ],
      },
      {
        itemNo: "03",
        title: "Comprehensive IT Assets",
        points: [
          "Support teaching, learning, & research",
          "Ensuring optimal performance and accessibility",
          "Seamless integration with educational activities",
        ],
      },
      {
        itemNo: "04",
        title: "Curriculum Enrichment",
        points: [
          "integrating hands-on activities in learning",
          "Fostering deeper understanding and skill development",
          "Enriching curriculum with practical exercises and real-world",
        ],
      },
    ],
  },
  {
    heighlight: "Digi",
    heading: "tal Footprint",
    des: "Enhance learning with our comprehensive digital platform, offering virtual labs, industry tools, and curated resources.",
    items: [
      {
        itemNo: "01",
        title: "Robust Learning Management System",
        points: [
          "Serves as a cornerstone for course management",
          "Accessible course material and engagement in discussions",
          "Facilitating a dynamic and interactive learning environment",
        ],
      },
      {
        itemNo: "02",
        title: "Virtual Labs and Simulators",
        points: [
          "Remote experimentation and practice ",
          "Enable students to conduct experiments and simulations",
          "Enhancing accessibility and flexibility in their learning journey ",
        ],
      },
      {
        itemNo: "03",
        title: "Industry-Relevant Software Tools",
        points: [
          "Access to industry-standard software tools",
          "Essential for skill development and proficiency",
          "gain practical experience and a competitive edge",
        ],
      },
      {
        itemNo: "04",
        title: "Digital Resource Repositories",
        points: [
          "Accessible learning materials",
          "Centralized hub for educational resources",
          "Streamlined learning process and experience",
        ],
      },
    ],
  },
  {
    heighlight: "Reso",
    heading: "urce Pool",
    des: "Expert trainers offer real-world insights and mentorship, enriching learning for success, while technical support ensures uninterrupted learning experiences.",
    items: [
      {
        itemNo: "01",
        title: "Industry Experts as Trainers",
        points: [
          "Offers invaluable perspectives and mentorship",
          "Trainers to impart real-world insights & guidance",
          "Enriching learning experience and preparing for success",
        ],
      },
      {
        itemNo: "02",
        title: "Technical Support Staff",
        points: [
          "Providing assistance and troubleshooting",
          "Ensuring smooth operation of equipment and software",
          "learning without interruptions or technical challenges",
        ],
      },
      {
        itemNo: "03",
        title: "Industry Practitioners as Guest Lecturers",
        points: [
          "Enrichment of academic discourse",
          "Bridging the gap between theory and practice.",
          "Guest lectures offer valuable insights of industry",
        ],
      },
      {
        itemNo: "04",
        title: "Training & Industry Coordinators",
        points: [
          "Ensures relevance & effectiveness of our educational initiatives",
          "Facilitates seamless coordination between education & industrial collaboration",
          "Organizing training sessions & fostering partnerships with industry stakeholders",
        ],
      },
    ],
  },
];
