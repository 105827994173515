import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import bridge from "../asset/images/bridge.png";
import { BridgeData } from "../data/home/BridgeData";
import React, { useState } from "react";
import { Box } from "@mui/system";
import StarPurple500Icon from "@mui/icons-material/StarPurple500";

const Bridge = () => {
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };
  return (
    <>
      <Grid>
        <Typography
          color="black"
          sx={{ fontSize: { lg: 40, md: 32, xs: 24 }, fontWeight: "bold" }}
        >
          <span
            style={{
              borderBottom: "4px solid black",
              paddingBottom: "8px",
            }}
          >
            Brid
          </span>
          ging Industry & Academia - 3D Collaboration
        </Typography>
      </Grid>

      <Grid container columnSpacing={5} mt={4}>
        <Grid item xs={12} lg={6}>
          {BridgeData.map((item, i) => (
            <Accordion
            key={i}
            expanded={expanded === `panel${i}`}
            onChange={handleChange(`panel${i}`)}
              sx={{
                border: "1px solid black",
                borderRadius: "25px !important",
                padding: "10px 0 0 0",
                marginBottom: "15px",
              }}
            >
              <AccordionSummary
               aria-controls={`panel${i}-content`}
               id={`panel${i}-header`}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box>
                    <img src={item.bullImg} alt="bullet" />
                  </Box>
                  <Typography
                    sx={{ fontSize: { lg: 22, md: 20, xs: 18 } }}
                    fontWeight="bold"
                    ml={3}
                  >
                    {item.title}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      paddingLeft: {
                        xs: "1.5em",
                        sm: "6em",
                        md: "5.5em",
                      },
                      marginTop: "-2em",
                      fontSize: {lg:18, md: 16, xs: 14 },
                    }}
                  >
                    {item.points.map((point) => (
                        <ListItem>
                        <ListItemIcon>
                            <StarPurple500Icon
                              sx={{ color: "black" }}
                              fontSize="xs"
                            />
                          </ListItemIcon>
                        <ListItemText
                          sx={{
                            color:"grey"
                          }}
                          primary={point}
                        />
                        </ListItem>
                    ))}
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>

        <Grid item xs={12} lg={6}>
          <img src={bridge} width={"100%"} height={"auto"} />
        </Grid>
      </Grid>
    </>
  );
};

export default Bridge;
