import React from "react";
import { Grid, Typography } from "@mui/material";
import rectangle1 from "../asset/images/Rectangle1.png";
import iconImg from "../asset/images/BulletIcon.png";
import SayHelloButton from "./SayHelloButton";
import { Box } from "@mui/system";

const data = [
  {
    text: "Cutting-Edge Curriculum crafted by industry experts",
  },
  {
    text: "Experiential Learning to apply theoretical knowledge in real-world scenarios",
  },
  {
    text: "Specialized Learning Paths tailored to prepare you to achieve your goals",
  },
];

const ImgCard1 = () => {
  return (
    <>
      <Grid>
        <Typography
          color="primary"
          sx={{ fontSize: { lg: 20, md: 18, xs: 16 } }}
        >
         Empower Your Future
        </Typography>
        <Typography
          color="black"
          mb={4}
          sx={{ fontSize: { lg: 40, md: 32, xs: 24 }, fontWeight: "bold" }}
        >
          <span
            style={{
              borderBottom: "4px solid black",
              paddingBottom: "8px",
            }}
          >
            Why
          </span>{" "}
          InAut<strong style={{ color: "#129D5A" }}>o</strong>mate?
        </Typography>
      </Grid>

      <Grid container columnSpacing={8} mt={8}>
        <Grid item xs={12} md={12} lg={6}>
          <img src={rectangle1} width={"100%"} height={"auto"} />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <Typography py={2} sx={{ fontSize: { lg: 36, md: 24, xs: 20 } }}>
          Innovation Meets Opportunity
          </Typography>
          <Typography
            paragraph={true}
            color="grey"
            lineHeight="30px"
            align="justify"
            fontSize="18px"
            pb={2}
          >
            At InAutomate, we provide personalized learning routes to match your objectives and
            skill level, ensuring that everyone can flourish in today's dynamic
            tech landscape. Our inclusive programs are designed to empower you to
            succeed in the ever-changing market.
          </Typography>

          {data.map((item) => (
            <Grid container textAlign="center">
              <Grid item xs={1}>
                <img src={iconImg} width={"50%"} />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  paragraph={true}
                  color="grey"
                  lineHeight="30px"
                  align="justify"
                  fontSize="18px"
                >
                  {" "}
                  {item.text}
                </Typography>
              </Grid>
            </Grid>
          ))}

          {/* <Box
            sx={{
              mt: { xl: 6, xs: 3 },
              display: { lg: "none", xl: "block", xs: "block" },
              textAlign: { lg: "left", xs: "center" },
            }}
          >
            <SayHelloButton learn={"Learn More"} />
          </Box> */}
        </Grid>
      </Grid>
    </>
  );
};

export default ImgCard1;
