import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import DropdownMenu from "./DropdownMenu";
import { Box } from "@mui/system";
import { linkClasses } from "@mui/material";
import { Link } from "react-router-dom";

const MenuItemList = ({ item }) => {
  const [isActive, setIsActive] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <>
      <Button
        key={item.title}
        onClick={item.menu && handleOpenNavMenu}
        sx={{
          my: 2,
          pl: 2,
          color: "white",
          color: "black",
          fontSize: "15px",
          textTransform:"capitalize",
        }}
        endIcon={
          item.menu &&
          (isActive ? (
            <KeyboardArrowUpIcon onClick={handleCloseUserMenu} />
          ) : (
            <KeyboardArrowDownIcon onClick={handleOpenNavMenu} />
          ))
        }
      >
        {item.menu ? (
          <>{item.title}</>
        ) : (
          <Link
            to={item.link}
            style={{ textDecoration: "none", color: "black", fontSize: "15px" }}
          >
            {item.title}
          </Link>
        )}
      </Button>
      {item.menu && (
        <DropdownMenu
          menuData={item.menu}
          handleCloseUserMenu={handleCloseUserMenu}
          anchorElNav={anchorElNav}
          handleOpenNavMenu={handleOpenNavMenu}
          setAnchorElNav={setAnchorElNav}
        />
      )}
    </>
  );
};

const Navbar = ({ navTitle }) => {
  return (
    <>
      {navTitle.map((item) => (
        <Box>
          <MenuItemList key={item.title} item={item} />
        </Box>
      ))}
    </>
  );
};

export default Navbar;
