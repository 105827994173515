import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import logo from "../asset/images/logo.png";
import SayHelloButton from "./SayHelloButton";
import Navbar from "./Navbar";
import MobileNavbar from "./MobileNavbar";
import StarBorderPurple500OutlinedIcon from "@mui/icons-material/StarBorderPurple500Outlined";
import { Link } from "react-router-dom";

const navTitle = [
  {
    icon: <StarBorderPurple500OutlinedIcon color="primary" />,
    title: "Career Shaping",
    link: "/career-shaping",
  },
  {
    icon: <StarBorderPurple500OutlinedIcon color="primary" />,
    title: "Eco System",
    link: "/echo-system",
  },
  {
    icon: <StarBorderPurple500OutlinedIcon color="primary" />,
    title: "About Us",
    link: "/about",
  },
  {
    title: "Career",
    link: "/career",
  },
];

const TopHeader = () => {
  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: "white", color: "black", boxShadow: "none" }}
    >
      <Container
        sx={{
          maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
          pt: 1,
        }}
      >
        <Toolbar disableGutters>
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
              width="190px"
            />
          </Link>

          {/*---------------- Mobile View------------------ */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <MobileNavbar navTitle={navTitle} />
          </Box>
          {/*---------------- Mobile View------------------ */}

          <Box flexGrow={1} />
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Navbar navTitle={navTitle} />
          </Box>

          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "none", md: "none", sm: "block", lg: "block" },
            }}
          >
            <SayHelloButton hello1={"Say Hello"} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopHeader;
