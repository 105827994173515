import { Grid, Typography, imageListClasses } from "@mui/material";
import React from "react";
import numberLine from "../asset/images/line1-2.png";
import number3 from "../asset/images/number-3.png";
import Line4 from "../asset/images/line-4.png";
import vrLine from "../asset/images/vrLine.png";
import round1 from "../asset/images/resumeRound.png";
import round2 from "../asset/images/initialround.png";
import round3 from "../asset/images/hrTechnicalround.png";
import round4 from "../asset/images/finalround.png";
import { Box } from "@mui/system";

const Recruitment = () => {
  return (
    <div>
      <Typography
        mb={5}
        sx={{
          fontWeight: "bold",
          lineHeight: { lg: "65px", md: "46px" },
          marginTop: { xl: 8, md: 0 },
          fontSize: { lg: 40, md: 35, xs: 28 },
          textAlign: { md: "left", xs: "center" },
        }}
      >
        <span
          style={{
            borderBottom: "4px solid black",
            paddingBottom: "8px",
          }}
        >
          Our{" "}
        </span>
        Recruitment Process
      </Typography>

      <Grid container justifyContent="space-between">
        <Grid item lg={5} xs={12} pt={2}>
          <Typography
            color="secondary.purpal"
            pt={3}
            sx={{ fontSize: { lg: 30, md: 28, xs: 26 }, fontWeight: "600" }}
          >
            Resume Submission
          </Typography>
          <Typography
            pt={3}
            pr={2}
            color="common.grey"
            textAlign="justify"
            lineHeight="2.1rem"
            sx={{ fontSize: { lg: 20, md: 18, xs: 16 } }}
          >
            In the current job market, your cover letter and resume are your
            first chance to leave a lasting impression on prospective employers.
            With thousands of applications streaming in for every job vacancy,
            it's vital to make your application stand out from the throng.
          </Typography>
        </Grid>
        <Grid
          item
          lg={2}
          sx={{ display: { lg: "block", xs: "none" } }}
          textAlign="center"
        >
          <Box component="img" src={numberLine} />
        </Grid>
        <Grid item lg={5} xs={12} sx={{ pt: { lg: 10, xs: 3 } }}>
          <Box component="img" src={round1} width="100%" />
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between">
        <Grid item lg={5} xs={12} pt={4}>
          <Box component="img" src={round2} width="100%" />
        </Grid>
        <Grid
          item
          lg={2}
          sx={{ display: { lg: "block", xs: "none" } }}
          textAlign="center"
        >
          <Box component="img" src={number3} />
        </Grid>
        <Grid item lg={5} xs={12}>
          <Typography
            color="secondary.purpal"
            pt={3}
            sx={{ fontSize: { lg: 30, md: 28, xs: 26 }, fontWeight: "600" }}
          >
            Initial Screening
          </Typography>
          <Typography
            pt={3}
            pr={2}
            color="common.grey"
            textAlign="justify"
            lineHeight="2.1rem"
            sx={{ fontSize: { lg: 20, md: 18, xs: 16 } }}
          >
            Congratulations! You've submitted an excellent CV and letter of
            application, and now it's time for the next stage of the recruiting
            process: the initial phone screening. This critical stage serves as
            the link between your resume and future face-to-face interviews.
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between">
        <Grid item lg={5} xs={12}>
          <Typography
            color="secondary.purpal"
            pt={2}
            sx={{ fontSize: { lg: 30, md: 28, xs: 26 }, fontWeight: "600" }}
          >
            Technical Rounds
          </Typography>
          <Typography
            pt={3}
            pr={2}
            color="common.grey"
            textAlign="justify"
            lineHeight="2.1rem"
            sx={{ fontSize: { lg: 20, md: 18, xs: 16 } }}
          >
            You've progressed to the next round of the hiring process: the
            technical and HR rounds. These rounds are critical for evaluating
            your skill set, cultural fit, and general suitability for the role
            and firm.
          </Typography>
        </Grid>
        <Grid
          item
          lg={2}
          sx={{ display: { lg: "block", xs: "none" } }}
          textAlign="center"
        >
          <Box component="img" src={Line4} />
        </Grid>
        <Grid item lg={5} xs={12} sx={{ pt: { lg: 0, xs: 3 } }}>
          <Box component="img" src={round3} width="100%" />
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between" pb={5}>
        <Grid item lg={5} xs={12}>
          <Box
            component="img"
            src={round4}
            sx={{ pt: { lg: 0, xs: 3 } }}
            width="100%"
          />
        </Grid>
        <Grid
          item
          lg={2}
          sx={{ display: { lg: "block", xs: "none" } }}
          textAlign="center"
        >
          <Box component="img" src={vrLine} />
        </Grid>
        <Grid item lg={5} xs={12}>
          <Typography
            color="secondary.purpal"
            sx={{ fontSize: { lg: 30, md: 28, xs: 26 }, fontWeight: "600" }}
          >
            Final Round
          </Typography>
          <Typography
            pt={3}
            pr={2}
            color="common.grey"
            textAlign="justify"
            lineHeight="2.1rem"
            sx={{ fontSize: { lg: 20, md: 18, xs: 16 } }}
          >
            Congratulations on making it this far in our selection process. In
            this pivotal stage, we aim to delve deeper into your qualifications,
            assess your fit within our organization, and determine your
            readiness to contribute to our team. Best of luck, and we look
            forward to meeting you!
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Recruitment;
