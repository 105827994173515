export const homepage = [
  {
    number: "01",
    text: "Job-readiness with a combination of",
    heighlight: "soft and technical skills",
  },

  {
    number: "02",
    text: "Entrepreneurial mindset & business acumen foster",
    heighlight:"innovation and leadership",
  },

  {
    number: "03",
    text: "Strong foundation in",
    heighlight: "cybersecurity and ethical AI",
    textextra:"ensures responsible practices"
  },

  {
    number: "04",
    text: "Exposure to global standards and collaboration with",
    heighlight: "industry professionals offer in valuable insights.",
  },

  {
    number: "05",
    text: "Keeping pace with tech evolution ensures graduates are",
    heighlight: "well-prepared for the future job market",
  },
];

export const inculsifyHover = [
  {
    number: "01",
    text: "Let's move beyond old-school learning methods and find better ways to prepare people for the future to",
    heighlight: "embrace the change",
  },

  {
    number: "02",
    text: "Today's tech is all about interconnectedness. We need to rethink how we teach to keep up with",
    heighlight: "connected technologies.",
  },

  {
    number: "03",
    text: "Products are evolving faster than ever. We need to make sure our talents are ready to jump in and contribute right away for",
    heighlight: "quick adaptation.",
  },

  {
    number: "04",
    text: "Bringing different technologies together can unlock incredible possibilities. Let's",
    heighlight: "empower individuals",
    textextra:"to create the next big thing."
  },

  {
    number: "05",
    text: "It's not just about technical skills. We need to focus on developing",
    heighlight: "well-rounded individuals",
    textextra:"who can thrive in any situation."
  },

];

export const fullspectrum = [
  [
    {
      number: "01",
      heighlight:"Pro Mentors:",
      textextra:"Get top-notch guidance from experienced professionals who are here to mentor you every step of the way."
    },

    {
      number: "02",
      heighlight:"Hands-On Experience:",
      textextra:"Dive into real-world projects and practical applications that pave the path for your future learning journey."
    },

    {
      number: "03",
      heighlight:"Real-World Learning:",
      textextra:"Our curriculum is designed to ensure you develop skills that directly translate to success in your chosen field."
    },

    {
      number: "04",
      heighlight:"Comprehensive Training:",
      textextra:"Experience a well-rounded approach to learning, seamlessly blending robotics and software expertise with essential life skills."
    },

    {
      number: "05",
      heighlight:"Soft Skills Blend:",
      textextra:"Explore the perfect mix of hardware, coding, and essential soft skills to nurture your professional growth."
    },
  ],
  [
    {
      number: "01",
      heighlight: "Embrace Change:",
      textextra:"Let's move beyond old-school learning methods and find better ways to prepare people for the future.",
    },

    {
      number: "02",
      heighlight: "Connected Technologies:",
      textextra:"Today's tech is all about interconnectedness. We need to rethink how we teach to keep up.",
    },

    {
      number: "03",
      heighlight: "Quick Adaptation:",
      textextra:"Products are evolving faster than ever. We need to make sure our talents are ready to jump in and contribute right away.",
    },

    {
      number: "04",
      heighlight: "Empower through Integration:",
      textextra:"Bringing different technologies together can unlock incredible possibilities. Let's empower individuals to create the next big thing.",
    },

    {
      number: "05",
      heighlight: "Overall Development:",
      textextra:"It's not just about technical skills. We need to focus on developing well-rounded individuals who can thrive in any situation.",
    },
  ],
];

export const industry = [
    {
      number: "01",
      heighlight: "Job-readiness",
      textextra:"with a combination of soft and technical skills.",
    },
  
    {
      number: "02",
      heighlight: "Entrepreneurial mindset and business acumen",
      textextra:"foster innovation and leadership."
    },
  
    {
      number: "03",
      text: "Strong foundation in cybersecurity and ethical AI",
      heighlight: "ensures responsible practices.",
    },
  
    {
      number: "04",
      text: "Exposure to global standards and",
      heighlight: "collaboration with industry professionals offer invaluable insights.",
    },
  
    {
      number: "05",
      text: "Keeping pace with tech evolution ensures graduates are",
      heighlight: "well-prepared for the future job market.",
    },
  ];

  export const skillAugmentation = [
        {
            number: "01",
            heighlight: "Interactive and Exciting Methods:",
            textextra: "Say goodbye to boring lectures and hello to dynamic, engaging techniques that keep you hooked.",
          },
        
          {
            number: "02",
            heighlight: "Teamwork and Connection:",
            textextra: "Collaborate with others and learn together, because sometimes the best lessons come from each other.",
          },
        
          {
            number: "03",
            heighlight: "Real-World Relevance:",
            textextra: "Apply what you learn to real-life situations, so you're ready to tackle challenges head-on.",
          },
        
          {
            number: "04",
            heighlight: "Agility and Proficiency:",
            textextra: "Develop the ability to adapt quickly and perform tasks with skill and efficiency.",
          },
        
          {
            number: "05",
            heighlight: "Whole-Person Growth:",
            textextra: "It's not just about what you know, it's about developing essential skills that make you a well-rounded individual.",
          },
  ];

  export const freshApproch = [
    {
      number: "01",
      heighlight: "Embrace Change:",
      textextra:"Let's move beyond old-school learning methods and find better ways to prepare people for the future."
    },
  
    {
      number: "02",
      heighlight: "Connected Technologies:",
      textextra:"Today's tech is all about interconnectedness. We need to rethink how we teach to keep up."
    },
  
    {
      number: "03",
      heighlight: "Quick Adaptation:",
      textextra:"Products are evolving faster than ever. We need to make sure our talents are ready to jump in and contribute right away."
    },
  
    {
      number: "04",
      heighlight: "Empower through Integration:",
      textextra:"Bringing different technologies together can unlock incredible possibilities. Let's empower individuals to create the next big thing."
    },
  
    {
      number: "05",
      heighlight: "Overall Development:",
      textextra:"It's not just about technical skills. We need to focus on developing well-rounded individuals who can thrive in any situation."
    },
  
  ];


