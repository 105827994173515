import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import bridge from "../asset/images/bridge.png";
import { UspData } from "../data/home/BridgeData";
import { Box } from "@mui/system";

const UspAccordian = () => {
  return (
    <>
      <Grid>
        <Typography
          color="primary"
          sx={{ fontSize: { lg: 20, md: 18, xs: 16 } }}
        >
         Core Specs
        </Typography>
        <Typography
          color="black"
          mb={4}
          sx={{
            fontSize: { lg: 40, md: 32, xs: 24 },
            fontWeight: "bold",
          }}
        >
          <span
            style={{
              borderBottom: "4px solid black",
              paddingBottom: "8px",
            }}
          >
            USPs
          </span>
        </Typography>
      </Grid>
      <Grid container columnSpacing={5} mt={4}>
        <Grid item xs={12}>
          {UspData.map((item, index) => (
            <Accordion
              defaultExpanded={index == 0 ? true : false}
              sx={{
                border: "1px solid black",
                borderRadius: "25px !important",
                padding: "10px 0 0 0",
                marginBottom: "15px",
              }}
              key={index}
            >
              <AccordionSummary
                expandIcon={<ArrowOutwardIcon sx={{ marginRight: "1em" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box>
                    <img src={item.bullImg} alt="bullet" />
                  </Box>
                  <Typography
                    sx={{ fontSize: { lg: 22, md: 20, xs: 18 } }}
                    fontWeight="bold"
                    ml={3}
                  >
                    {item.title}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    paddingLeft: {
                      xs: "0em",
                      sm: "5.5em",
                      md: "6.5em",
                      lg: "7em",
                      xl: "5.5em",
                    },
                    marginTop: "-2em",
                    fontSize: { md: 18, xs: 16 },
                  }}
                  color="grey"
                >
                  <span>{item.des}</span>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default UspAccordian;
