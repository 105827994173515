import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import StarPurple500Icon from "@mui/icons-material/StarPurple500";

const Experties = ({ data }) => {
  return (
    <>
      {data.map((expData, expDataIndex) => (
        <Box key={expDataIndex} mt={8}>
          <Grid container columnSpacing={3} mt={1}>
            <Grid item xs={12} md={4} lg={5}xl={4}>
              <Typography mb={3} sx={{ fontSize: { lg: 36, md: 24, xs: 20 } }}>
              <span
                    style={{
                      borderBottom: "4px solid black",
                      paddingBottom: "8px",
                    }}
                  >{expData.heighlight}</span>{expData.heading}
               
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={6} xl={8}>
              <Typography
                paragraph={true}
                color="grey"
                lineHeight="30px"
                align="justify"
                fontSize="20px"
              >
                {expData.des}
              </Typography>
            </Grid>
          </Grid>
          
          <Grid container rowSpacing={3} mt={1}>
            {expData.items.map((item, itemsindex) => (
              <Grid item xs={12} md={6}  lg={6} key={itemsindex}>
                <Grid container columnSpacing={2}>
                  <Grid item lg={5} md={7} xs={5}>
                    <Typography
                      sx={{ fontSize: { lg: 20, xs: 18 } }}
                      fontWeight={"bold"}
                      lineHeight={"24px"}
                    >
                      {item.title}
                    </Typography>
                  </Grid>
                  <Grid item lg={4} md={5} xs={4}>
                    <Typography
                      sx={{
                        fontSize: { lg: 48, md: 40, xs: 30 },
                        lineHeight: { lg: "50px", md: "38px", xs: "32px" },
                      }}
                      fontWeight={"bold"}
                      align="center"
                    >
                      <span
                        style={{
                          backgroundImage:
                            "linear-gradient(180deg, #e89b0e80 10%, #CFCCC5 100%)",
                          WebkitTextFillColor: "transparent",
                          backgroundClip: "text",
                        }}
                      >
                        {item.itemNo}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>

                <List>
                  {item.points.map((point, pointsindex) => (
                    <ListItem
                      disablePadding
                      alignItems="start"
                      key={pointsindex}
                    >
                      <StarPurple500Icon fontSize="xs" />
                      <ListItemText
                        sx={{
                          fontSize: "16px",
                          color: "grey",
                          paddingLeft: "2px",
                        }}
                        primary={point}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </>
  );
};

export default Experties;
