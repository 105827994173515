import React from "react";
import Layout from "../component/Layout";
import CardHover from "../component/CardHover";
import { homepage } from "../data/home/standCard";
import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ImgCard1 from "../component/ImgCard1";
import TrainingCard from "../component/TrainingCard";
import CollapseCards from "../component/CollapseCards";
import SkillCard from "../component/SkillCard";
import Bridge from "../component/Bridge";
// import Testinomial from "../component/Testinomial";

const Home = () => {
  return (
    <>
      <Layout>
        <Container
          sx={{
            maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <TrainingCard />
          </Box>

          <Box sx={{ flexGrow: 1, marginTop: "40px" }}>
            <ImgCard1 />
          </Box>

          <Box sx={{ flexGrow: 1, mt: 8 }}>
            <Typography
              color="primary"
              sx={{ fontSize: { lg: 20, md: 18, xs: 16 } }}
            >
              Discover our services
            </Typography>
            <CollapseCards />
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Grid xs={12} mt={5}>
              <Typography
                color="black"
                mb={5}
                sx={{
                  fontSize: { lg: 40, md: 32, xs: 24 },
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    borderBottom: "5px solid black",
                    paddingBottom: "15px",
                  }}
                >
                  Uni
                </span>
                que Approach & Results
              </Typography>
              <Typography
                paragraph={true}
                color="grey"
                lineHeight="30px"
                align="justify"
                fontSize="18px"
                mb={5}
              >
                At InAutomate, we employ a distinctive methodology that combines
                cutting-edge technology with personalized guidance, ensuring
                each individual's journey is tailored to their specific needs
                and goals. Our commitment to excellence has led to tangible
                outcomes, with our participants consistently achieving
                remarkable success in their careers. From securing coveted
                positions to spearheading innovative projects, the results speak
                for themselves.
              </Typography>
            </Grid>
            <Grid container spacing={3}>
              {homepage.map((item) => (
                <CardHover
                  title={item.number}
                  text={item.text}
                  heighlight={item.heighlight}
                  extraText={item.textextra}
                />
              ))}
            </Grid>
          </Box>

          <Box sx={{ flexGrow: 1, mt: 8 }}>
            <SkillCard />
          </Box>
        </Container>

        <Box sx={{ background: "rgba(3, 3, 245, 0.03)" }} mx={0} py={4} my={4}>
          <Container
            sx={{
              maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
            }}
          >
            <Bridge />

            {/* <Testinomial/> */}
          </Container>
        </Box>
      </Layout>
    </>
  );
};

export default Home;
