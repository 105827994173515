import React from "react";
import Layout from "../component/Layout";
import { Box, Container } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import GetButton from "../component/GetButton";
import VisionCard from "../component/VisionCard";
import { fullspectrum } from "../data/home/standCard";
import CardHover from "../component/CardHover";
import ValueCard from "../component/ValueCard";
import ValueCardMobile from "../component/ValueCardMobile";

const About = () => {
  return (
    <>
      <Layout>
        <Container
          sx={{
            maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
          }}
        >
          <Box mt={5}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  mb={5}
                  sx={{
                    fontWeight: "bold",
                    lineHeight: { lg: "65px", md: "46px" },
                    marginTop: { xl: 8, md: 0 },
                    fontSize: { lg: 60, md: 40, xs: 30 },
                    textAlign: { md: "left", xs: "center" },
                  }}
                >
                  <span
                    style={{
                      borderBottom: "4px solid black",
                      paddingBottom: "8px",
                    }}
                  >
                    Ab
                  </span>
                  out <strong style={{ color: "#129D5A" }}>Us</strong>
                </Typography>

                <Typography
                  paragraph={true}
                  color="grey"
                  lineHeight="30px"
                  align="justify"
                  sx={{
                    textAlign: { md: "left", xs: "center" },
                    fontSize: { lg: 20, md: 18, xs: 16 },
                  }}
                >
                  Welcome to InAutomate, where we're shaping the future of
                  learning in robotics and software. Our passion lies in merging
                  innovation with education to cultivate the leaders of
                  tomorrow. As a pioneering training and skill development
                  company, we're dedicated to empower individuals to excel in
                  the digital era. At InAutomate, we believe in pushing
                  boundaries and embracing change. We're not just about
                  teaching; we're about transforming lives and unlocking
                  potential. With a focus on{" "}
                  <span style={{ color: "black" }}>
                    cutting-edge technologies and forward-thinking
                    methodologies, we're here to guide you on a journey of
                    discovery and growth.
                  </span>{" "}
                  Join us as we embark on this exciting adventure together,where
                  possibilities are endless, and the future is waiting to be
                  shaped.Together, let's discover the endless possibilities and
                  redefine the future of learning with InAutomate.
                </Typography>

                <Box
                  sx={{
                    marginTop: { md:2, lg:3, xl:5 },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <GetButton hello1={"Say Hello"} />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box flex={{ flexGrow: 1 }} mt={5}>
            <Grid xs={12} >
              <Typography
                color="black"
                mb={7}
                sx={{
                  fontSize: { lg: 40, md: 32, xs: 24 },
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    borderBottom: "4px solid black",
                    paddingBottom: "8px",
                  }}
                >
                  Wha
                </span>
                t InAut<strong style={{ color: "#129D5A" }}>o</strong>mate Gives
                You
              </Typography>
            </Grid>

            <Grid container spacing={3}>
              {fullspectrum[0].map((item, index) => (
                <CardHover
                  title={item.number}
                  heighlight={item.heighlight}
                  extraText={item.textextra}
                />
              ))}
            </Grid>
          </Box>
          

          <Box flexGrow={1} mt={5}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  mb={5}
                  sx={{
                    fontWeight: "bold",
                    lineHeight: { lg: "50px", md: "30px" },
                    marginTop: { xl: 8, md: 0 },
                    fontSize: { lg: 40, md: 25 },
                    textAlign: { md: "left", xs: "center" },
                  }}
                >
                  <span
                    style={{
                      borderBottom: "4px solid black",
                      paddingBottom: "8px",
                    }}
                  >
                    Our{" "}
                  </span>
                  Aim
                </Typography>
                <Typography
                  paragraph={true}
                  color="grey"
                  lineHeight="30px"
                  align="justify"
                  sx={{
                    textAlign: { md: "left", xs: "center" },
                    fontSize: { lg: 20, md: 18, xs: 16 },
                  }}
                >
                  At InAutomate, our aim is to empower individuals with the
                  skills and knowledge they need to thrive in the ever-evolving
                  fields of robotics and software. We're here to bridge the gap
                  between education and industry, providing practical training
                  and real-world insights that prepare our learners for success.
                  Our goal is to inspire, innovate, and ignite a passion for
                  learning, enabling each individual to reach their fullest
                  potential and become leaders in their respective fields.
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box mt={5} flexGrow={1}>
            <VisionCard />
          </Box>

          <Box mt={5} flexGrow={1}>
          <Typography
                  mb={5}
                  sx={{
                    fontWeight: "bold",
                    lineHeight: { lg: "50px", md: "30px" },
                    marginTop: { xl: 8, md: 0 },
                    fontSize: { lg: 40, md: 25 },
                    textAlign: { md: "left", xs: "center" },
                  }}
                >
                  <span
                    style={{
                      borderBottom: "4px solid black",
                      paddingBottom: "8px",
                    }}
                  >
                    Our {" "}
                  </span>
                  Values
                </Typography>
            <ValueCard/>
            <ValueCardMobile />
            
          </Box>

        </Container>
      </Layout>
    </>
  );
};

export default About;

