import { Grid, Typography } from "@mui/material";
import iconImg from "../asset/images/BulletIcon.png";
import React from "react";

const AcademyCard = ({ data }) => {
  return (
    <>
      <Grid container columnSpacing={8} mt={3}>
      <Grid item xs={12} md={12} lg={6} mt={2}>
         
         <Typography
           paragraph={true}
           color="grey"
           lineHeight="30px"
           align="justify"
           fontSize="18px"
         >
           {data.des}
         </Typography>

         <Grid sx={{mt:{xl:5,xs:3}}}>
           {data.items.map((item, index) => (
             <Grid key={index} container mt={1}>
               <Grid item xs={1}>
                 <img src={iconImg} width={"50%"} />
               </Grid>
               <Grid item xs={11}>
                 <Typography
                   paragraph={true}
                   color="grey"
                   lineHeight="30px"
                   align="justify"
                   fontSize="18px"
                 >
                   {item}
                 </Typography>
               </Grid>
             </Grid>
           ))}
         </Grid>
       </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <img src={data.image} width={"100%"} height={"auto"} />
        </Grid>

     
      </Grid>
    </>
  );
};

export default AcademyCard;
