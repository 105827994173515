import { Button } from "@mui/material";
import React from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const GetButton = ({hello1,get}) => {
  return (
    <>
      <Button
        variant="outlined"
        sx={{
          px: 2.5,
          py: 1.5,
          fontSize: "15px",
          fontWeight: "bold",
          borderRadius: "30px",
          textTransform:"capitalize"
        }}
        component="a"
        href="mailto:learn@inautomate.in"
        endIcon={
          <ArrowOutwardIcon sx={{ fontSize: "20px", fontWeight: "bold" }} />
        }
      >
        { hello1 } {get}
      </Button>
    </>
  );
};

export default GetButton;
