import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Career from "./pages/Career";
import InculsifyHub from "./pages/InculsifyHub";
import ValueOfferings from "./pages/ValueOfferings";
import Pagenotfound from "./pages/Pagenotfound";
import Privacy from "./pages/Privacy";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/career" element={<Career />} />
        <Route path="/career-shaping" element={<InculsifyHub />} />
        <Route path="/echo-system" element={<ValueOfferings />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </>
  );
}

export default App;
