import React from "react";
import Layout from "../component/Layout";
import { Box, Container } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import GetButton from "../component/GetButton";
import { freshApproch } from "../data/home/standCard";
import CardHover from "../component/CardHover";
import { collaborativeData, expertiesData } from "../data/AcademyData";
import Experties from "../component/Experties";
import CollapseAcademyCard from "../component/CollapseAcademyCard";
import { synergicLearn } from "../data/CollapseAcaData";
import { CollaborativeCard } from "../component/CollaborativeCard";

const ValueOfferings = () => {
  return (
    <>
      <Layout>
        <Container
          sx={{
            maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
          }}
        >
          <Box mt={5}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  mb={5}
                  sx={{
                    fontWeight: "bold",
                    lineHeight: { lg: "65px", md: "46px" },
                    marginTop: { xl: 8, md: 0 },
                    fontSize: { lg: 60, md: 40, xs: 30 },
                    textAlign: { md: "left", xs: "center" },
                  }}
                >
                  <span
                    style={{
                      borderBottom: "4px solid black",
                      paddingBottom: "8px",
                    }}
                  >
                    Syner
                  </span>
                  gistic <strong style={{ color: "#129D5A" }}> Learning</strong>
                </Typography>

                <Typography
                  paragraph={true}
                  color="grey"
                  lineHeight="30px"
                  align="justify"
                  sx={{
                    textAlign: { md: "left", xs: "center" },
                    fontSize: { lg: 20, md: 18, xs: 16 },
                  }}
                >
                  Imagine the transformative synergy that unfolds when esteemed
                  academic institutions and In-Automate unite. This
                  collaboration epitomizes the convergence of scholarly pursuits
                  and industry prowess,{" "}
                  <span style={{ color: "black" }}>
                    birthing a realm of unprecedented opportunity and
                    innovation.
                  </span>
                  This collaboration creates a fertile ground for groundbreaking
                  research, real-world applications, and the emergence of
                  pioneering solutions that have the potential to revolutionize
                  entire industries.
                </Typography>

                <Box
                  sx={{
                    marginTop: { md: 4, lg: 5 },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <GetButton hello1={"Say Hello"} />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box flexGrow={1}>
            <CollapseAcademyCard data={synergicLearn} />
          </Box>

          <Box sx={{flexGrow:1}}>
          <Experties data={expertiesData}/>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Grid xs={12} mt={5}>
              <Typography
                color="black"
                mb={5}
                sx={{
                  fontSize: { lg: 40, md: 32, xs: 24 },
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    borderBottom: "4px solid black",
                    paddingBottom: "8px",
                  }}
                >
                  Coll
                </span>
                aborative Endeavors
              </Typography>
            </Grid>
            <CollaborativeCard data={collaborativeData} />
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Grid xs={12} mt={5}>
              <Typography
                color="black"
                mb={5}
                sx={{
                  fontSize: { lg: 40, md: 32, xs: 24 },
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    borderBottom: "4px solid black",
                    paddingBottom: "8px",
                  }}
                >
                  Fres
                </span>
                h Approach of Learning
              </Typography>
            </Grid>
            <Grid container spacing={3}>
              {freshApproch.map((item) => (
                <CardHover
                  title={item.number}
                  text={item.text}
                  heighlight={item.heighlight}
                  extraText={item.textextra}
                />
              ))}
            </Grid>
          </Box>
          
        </Container>
      </Layout>
    </>
  );
};

export default ValueOfferings;
