import { Grid, Typography } from "@mui/material";
import React from "react";
import iconImg from "../asset/images/BulletIcon.png";
import training from "../asset/images/training.png";
import GetButton from "./GetButton";
import { Box, borderRadius } from "@mui/system";

const TrainingCard = () => {
  return (
    <>
      <Grid container Spacing={5} mt={8}>
        <Grid item xs={12} md={6} lg={5}>
          <Typography
            mb={2}
            sx={{
              fontWeight: "bold",
              lineHeight: { xl: "60px", lg: "50px", md: "40px" },
              marginTop: { xl: 8, lg: 5, md: 0 },
              fontSize: { lg: 36, md: 28, xs: 24 },
              textAlign: { md: "left", xs: "center" },
            }}
          >
            Empowering Tomorrow's Innovators with Holistic{" "}
            <strong style={{ color: "#129D5A" }}>development​</strong>
          </Typography>

          <Typography
            paragraph={true}
            color="grey"
            lineHeight="30px"
            sx={{
              textAlign: { md: "justify", xs: "center" },
              fontSize: { lg: 20, md: 18, xs: 16 },
            }}
          >
            We at InAutomate are re-inventing the Future of Learning, where
            innovation meets education to shape the leaders of tomorrow,
            nurturing more adaptable & productive candidates with inclusive
            skills required for market. As a trailblazing training and skill
            development organization, we're committed to pushing the boundaries of
            conventional learning and empowering individuals to thrive in the
            digital age.
          </Typography>

          <Box
            sx={{
              marginTop: { md: 2, xl: 3 },
              display: { xs: "none", xl: "block" },
            }}
          >
            <GetButton get={"Get Started"} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={7}>
          <Box sx={{ textAlign: { md: "right", xs: "center" } }}>
            <img src={training} width={"80%"} height={"auto"} />
          </Box>
          <Box
            mt={3}
            sx={{ display: { md: "none", xs: "block", textAlign: "center" } }}
          >
            <GetButton get={"Get Started"} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TrainingCard;
