import iconImg1 from "../../asset/images/userIcon.png";
import iconImg2 from "../../asset/images/collegeIcon.png";
import iconImg3 from "../../asset/images/autoIcon.png";
import UspiconImg1 from "../../asset/images/UspIconImg1.png";
import UspiconImg2 from "../../asset/images/UspIconImg2.png";
import UspiconImg4 from "../../asset/images/UspIconImg4.png";
import UspiconImg5 from "../../asset/images/UspIconImg5.png";

export const BridgeData = [
  {
    title: "Pupil Standpoint",
    points: [
      "Refined Learning Opportunities",
      "Objective Learning",
      "Overall Professional development",
      "Role based Exposure",
      "Industry Exposure with Experts",
      "Talent showcase Opportunities",
    ],
    bullImg: iconImg1,
  },
  {
    title: "Institution Standpoint",
    points: [
      "Industry based Practical Approach",
      "Building Future ready Talent Pool",
      "Exposure to Industry experts",
      "R&D capability Indulgence",
      "Industry Collaboration",
      "Pool of Opportunities",
    ],
    bullImg: iconImg2,
  },
  {
    title: "InAutomate StandPoint",
    points: [
      "Lead the Change",
      "Future Ready Talent Pool",
      "Skill based Interactions",
      "Mass Engagement",
    ],
    bullImg: iconImg3,
  },
];

export const UspData = [
  {
    title: "Forging Connections: Holistic Approach to Collaborative Learning",
    des: "Have you ever thought about the incredible possibilities that arise when colleges and In-Automate join forces? It's like bringing together the best of both worlds  academia and industry  to create something truly remarkable.",
    bullImg: UspiconImg1,
  },
  {
    title: "Creating Opportunities Together",
    des: "When colleges partner with In-Automate, students gain access to both knowledge and real-world experience. This collaboration opens a treasure trove of opportunities for everyone involved.",
    bullImg: UspiconImg2,
  },
  {
    title: "Unlocking Potential in Robotics and Software Development",
    des: "Let's talk about robotics and software development – two fields that are shaping the future as we speak. By teaming up, colleges can offer students access to state-of-the-art labs, equipment, and expertise provided by In-Automate. This means hands-on learning experiences that go beyond textbooks – it's about getting hands on training and diving headfirst into the world of robotics and software.",
    bullImg: iconImg2,
  },
  {
    title: "Skill Augmentation for Tomorrow's Workforce",
    des: "But it's not just about technical skills – it's also about personal and professional growth. Collaboration between colleges and In-Automate can provide tailored training modules designed to enhance students' skill sets and prepare them for the demands of the modern workforce. Whether it's communication, problem-solving, or teamwork, these are the skills that will set students apart in their careers.",
    bullImg: UspiconImg4,
  },
  {
    title: "Fostering Innovation and Creativity",
    des: "Let's not forget about the magic that happens when diverse minds come together. Collaboration sparks innovation and creativity, leading to groundbreaking ideas and solutions that wouldn't be possible otherwise. By working hand in hand, colleges and In-Automate can push the boundaries of what's possible and pave the way for a brighter future.",
    bullImg: UspiconImg5,
  },
];
