import React, { useEffect } from "react";
import { Footer } from "./Footer";
import TopHeader from "./Header";
import { ThemeProvider } from "@emotion/react";
import BaseTheme from "../utils/theme";

const Layout = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <ThemeProvider  theme={BaseTheme}>
    <TopHeader />
      {children}
      <Footer />
    </ThemeProvider>
      
    </>
  );
};

export default Layout;
