import React from "react";
import ConnectCard from "./ConnectCard";
import { Grid, Box, Container, Typography } from "@mui/material";
import logo from "../asset/images/logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Facebook } from "@mui/icons-material";

export const Footer = () => {
  return (
    <>
      <Box sx={{ background: "rgba(3, 3, 245, 0.03)" }} py={4} mt={5} mb={2}>
        <Container
          sx={{
            maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
          }}
        >
          <ConnectCard />
          <Box
            sx={{ display: { xs: "block", sm: "none" } }}
            textAlign={"left"}
            ml={2}
          >
            <InstagramIcon sx={{ mr: "20px" }} />
            <XIcon sx={{ mr: "20px" }} />
            <LinkedInIcon sx={{ mr: "20px" }} />
          </Box>
        </Container>
      </Box>
      <Container
        sx={{
          maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
        }}
      >
        <Box>
          <Grid
            container
            justifyContent={"space-between"}
            textAlign="center"
            py={2}
          >
            <Grid
              item
              lg={3}
              md={3}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <img src={logo} alt="logo" width="55%" />
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography
                sx={{
                  fontSize: { lg: 18, xs: 16 },
                  fontWeight: "bold",
                  mt: { md: 5, xs: 0 },
                }}
                color={"grey"}
              >
                @2020 <a href="/privacy-policy" target="_self" style={{textDecoration:"none",color:"grey"}}>Privacy-Policy
                  </a>
              </Typography>
            </Grid>

            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              sx={{
                display: { xs: "none", sm: "block" },
                mt: { md: 5, xs: 0 },
              }}
            >
              <Box>
                <a
                  href="https://www.facebook.com/profile.php?id=61557717053226"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 {<Facebook sx={{ mr: "30px",color:"black", fontSize:"30px" }} />}
                </a>
                {/* <XIcon sx={{ mr: "20px", fontSize:"30px" }} /> */}
                <a
                  href="https://www.linkedin.com/company/in-automate/about/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 {<LinkedInIcon sx={{ mr: "30px",color:"black", fontSize:"30px" }} />}
                </a>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
