import { Card, CardHeader, Grid, Paper, Typography } from "@mui/material";
import React from "react";

const values = [
  {
    id: "1",
    title: "Innovation",
    text: "Embracing cutting-edge technologies and methodologies",
  },
  {
    id: "2",
    title: "Excellence",
    text: "Striving for the highest standards of quality in training delivery.",
  },
  {
    id: "3",
    title: "Empowerment",
    text: "Equipping individuals with skills to succeed in a rapidly evolving industry.",
  },
  {
    id: "4",
    title: "Adaptability",
    text: "Flexible and responsive to changes in technology & market demands.",
  },
  {
    id: "5",
    title: "Impact",
    text: "Making a positive difference in the lives and careers of individuals.",
  },
  {
    id: "6",
    title: "Integrity",
    text: "Upholding honesty, ethics, and transparency in all interactions.",
  },
  {
    id: "7",
    title: "Continuous Learning",
    text: "Promoting a culture of lifelong learning and skill development.",
  },
];
const ValueCardMobile = () => {
  return (
    <div>
      <Grid container spacing={3} justifyContent="center" sx={{display:{lg:"none", xs:"block"}}}> 
        {values.map((value) => (
          <Grid item md={6} xs={12}>
            <Card
              sx={{ border: "1px solid #E89B0E", borderRadius: "30px", p: 3 }}
              component={Paper}
              elevation={0}
            >
              <Typography sx={{ fontSize: { md: 22, xs: 20 }, fontWeight:"600", pb:1 }}>
                {value.title}
              </Typography>
              <Typography
                color="common.grey"
                sx={{ fontSize: { md: 20, xs: 18 },textAlign:"justify"}}
              >
                {value.text}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ValueCardMobile;
