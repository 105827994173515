import { Typography, Grid } from "@mui/material";
import iconImg from "../asset/images/BulletIcon.png";

import React from "react";
import { Box } from "@mui/system";


export const CollaborativeCard = ({data}) => {
  return (
    <>
      <Grid container spacing={2} columnSpacing={8}>
        <Grid item xs={12} md={12} lg={6}>
          <Typography
            paragraph={true}
            color="grey"
            lineHeight="30px"
            align="justify"
            fontSize="18px"
          >
            {data.des}
          </Typography>
          <img src={data.image} width={"100%"} height={"auto"} />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box>
            {data.details.map((detail, detailIndex) => (
              <Grid key={detailIndex} container mt={2}>
                <Grid item xs={1}>
                  <img src={iconImg} width={"50%"} alt="icon" />
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    paragraph={true}
                    color="grey"
                    lineHeight="30px"
                    align="justify"
                    fontSize="18px"
                  >
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      {detail.heighText}:
                    </span>{" "}
                    {detail.text}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
