import { Grid, Icon, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import iconImg from "../asset/images/BulletIcon.png";
import skill from "../data/home/skillData.json";
import StarPurple500Icon from '@mui/icons-material/StarPurple500';

const SkillCard = () => {
  return (
    <>
      <Grid>
        <Typography color="primary" sx={{fontSize:{lg:20, md:18, xs:16} }}>
          Learning Infrastructures & Assets
        </Typography>
        <Typography
          color="black"
          mb={5}
          sx={{fontSize:{lg:40, md:32, xs:24}  , fontWeight: "bold" }}
        >
          <span
            style={{
              borderBottom: "4px solid black",
              paddingBottom: "8px",
            }}
          >
            What
          </span>{" "}
          We Provide
        </Typography>
      </Grid>

      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={4} lg={5} xl={4}>
          <Typography mb={3} sx={{fontSize:{lg:36, md:24, xs:20} }}>
            InAut<strong style={{ color: "#129D5A" }}>o</strong>mate Skill
            Eco-System
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={7} xl={8}>
          <Typography
            paragraph={true}
            color="grey"
            lineHeight="30px"
            align="justify"
            fontSize="20px"
          >
            By staying at the forefront of innovation, we empower our students
            to stay ahead of the curve and excel in today's tech-driven world.
            Join us at InAutomate, where theory meets practice, and where every
            student is equipped to thrive in the digital age
          </Typography>
        </Grid>
      </Grid>

      <Grid container rowSpacing={3} mt={4}>
        {
            skill.map((item,index)=>(
       
        <Grid item xs={12} sm={6} md={6} xl={3} lg={4} key={index}>
          <Grid container columnSpacing={2}>
            <Grid item lg={5} >
              <Typography sx={{fontSize:{lg:24, xs:20}}} fontWeight={"bold"} lineHeight={"24px"}>
              {item.title}
              </Typography>
            </Grid>
            <Grid item lg={7}>
              <Typography sx={{fontSize:{lg:48, md:40, xs:30},lineHeight:{lg:"50px", md:"38px", xs:"32px"}}}  fontWeight={"bold"} align="center">
                <span
                  style={{
                    backgroundImage:
                      "linear-gradient(180deg, #e89b0e80 10%, #CFCCC5 100%)",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                  }}
                >
              {item.number}
                </span>
              </Typography>
            </Grid>
          </Grid>

          <List>
            <ListItem disablePadding alignItems="start"> 
              <StarPurple500Icon fontSize="xs"/>
            <ListItemText sx={{fontSize:"16px", color:"grey",paddingLeft:"2px"}} primary={item.point1} />
            </ListItem>
            <ListItem disablePadding alignItems="start"> 
              <StarPurple500Icon fontSize="xs"/>
            <ListItemText   sx={{fontSize:"16px", color:"grey",paddingLeft:"2px"}} primary={item.point2} />
            </ListItem>
            <ListItem disablePadding alignItems="start"> 
              <StarPurple500Icon fontSize="xs"/>
            <ListItemText   sx={{fontSize:"16px", color:"grey",paddingLeft:"2px"}} primary={item.point3} />
            </ListItem>
          </List>
        </Grid>
         ))}
      </Grid>
    </>
  );
};

export default SkillCard;
