import { Button } from "@mui/material";
import React from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const SayHelloButton = ({ learn, hello, hello1 }) => {
  return (
    <>
      <Button
        sx={{
          px: 2.2,
          py: 1.2,
          backgroundColor: "#E89B0E",
          color: "white",
          fontSize: { lg: "15px", sm: "12px" },
          fontWeight: "bold",
          borderRadius: "30px",
          textTransform: "capitalize",
          ":hover": {
            bgcolor: "#E89B0E",
            color: "white",
          },
        }}
        component="a"
        href="mailto:learn@inautomate.in"
        endIcon={
          <ArrowOutwardIcon sx={{ fontSize: "20px", fontWeight: "bold" }} />
        }
      >
        {learn} {hello} {hello1}
      </Button>
    </>
  );
};

export default SayHelloButton;
