import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import rectangle1 from "../asset/images/collapse1.png";
import rectangle2 from "../asset/images/collapse2.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import iconImg from "../asset/images/BulletIcon.png";
import { accordianHomeCollapse } from "../data/CollapseAcaData";

const CollapseCards = () => {
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  return (
    <>
      <Grid>
        <Typography
          color="black"
          mb={5}
          sx={{ fontSize: { lg: 40, md: 32, xs: 24 }, fontWeight: "bold" }}
        >
          <span
            style={{
              borderBottom: "4px solid black",
              paddingBottom: "8px",
            }}
          >
            What{" "}
          </span>
          makes us Stand Apart......
        </Typography>
        <Typography
          paragraph={true}
          color="grey"
          lineHeight="30px"
          align="justify"
          fontSize="18px"
        >
          In nurturing the next generation of professionals, it's crucial to
          prioritize current industry needs, equipping individuals with
          necessary skills and knowledge. Our curriculum emphasizes a balance of
          soft and technical skills, tailored for specific careers, to prepare
          professionals for success in ever-changing environments.
        </Typography>

        <Typography
          paragraph={true}
          color="grey"
          lineHeight="30px"
          align="justify"
          fontSize="18px"
        >
          We focus on nurturing entrepreneurial skills, promoting innovation,
          and developing business knowledge, alongside a dedication to
          continuous learning and understanding upcoming technology and ethical
          AI guidelines.
        </Typography>

        <Typography
          paragraph={true}
          color="grey"
          lineHeight="30px"
          align="justify"
          fontSize="18px"
        >
          We provide exposure to global standards and partnerships with industry
          leaders, offering valuable insights. Keeping up with emerging
          technologies like RPA, AI, and Edge Computing ensures graduates are
          well-prepared for the evolving job market. This approach prepares
          graduates to tackle sector-specific challenges and excel in their
          careers.
        </Typography>
      </Grid>

      <Grid container columnSpacing={5} mt={8}>
        <Grid item xs={12} md={12} lg={6}>
          <Box sx={{ marginBottom: { lg: "0", xs: "20px" } }}>
            <img src={rectangle2} width={"100%"} height={"auto"} />
          </Box>
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          {accordianHomeCollapse.map((data, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{
                border: "1px solid grey",
                borderRadius: "25px !important",
                padding: {
                  xl: "20px 10px 20px 10px",
                  xs: "10px 10px 10px 10px",
                },
                marginBottom: { xl: "20px", xs: "10px" },
              }}
            >
              <AccordionSummary
                expandIcon={<ArrowOutwardIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <img src={iconImg} alt="bullet" width="30px" />
                <Typography
                  sx={{ fontSize: { lg: 22, xs: 20 } }}
                  fontWeight="bold"
                  px={2}
                >
                  {data.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="grey" sx={{ fontSize: { lg: 18, xs: 16 } }}>
                  {data.des}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default CollapseCards;
