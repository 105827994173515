import { CardContent, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const CardHover = ({ text, title, heighlight,extraText }) => {
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          height: "346px",
          borderRadius: "31.16px",
          bgcolor: "#FAF8FE",
          justifyContent: "center",
          "& .myicon": {
            backgroundImage:
              "linear-gradient(180deg, #c7b2f5ff 0%, #aba9b100 100%)",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
          },
          ":hover": {
            bgcolor: "#c7b2f58c",
            border: "1px solid #7C4ED8",
            "& .myicon": {
              backgroundImage: "none",
              WebkitTextFillColor: "#7C4ED8",
              backgroundClip: "text",
            },
          },
        }}
      >
        <CardContent sx={{ pt:3, px:5, }}>
          <Typography sx={{ fontSize: 64, p: 1, gutterBottom: true }}>
            <span className="myicon">{title}</span>
          </Typography>

          <Typography sx={{ fontSize: 20, color: "#646464" }}>
            {text}
            <span style={{ fontSize: 20, color: "#222222" }}>{" "}{heighlight}{" "}</span>{extraText}
          </Typography>
          <Typography></Typography>
        </CardContent>
      </Paper>
    </Grid>
  );
};

export default CardHover;
