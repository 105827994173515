import React from "react";
import Layout from "../component/Layout";
import { Box, Container } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import GetButton from "../component/GetButton";
import StudentCard from "../component/StudentCard";
import Recruitment from "../component/Recruitment";
import HiringCards from "../component/HiringCards";

const Career = () => {
  return (
    <>
      <Layout>
        <Container
          sx={{
            maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
          }}
        >
          <Box mt={5}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  mb={5}
                  sx={{
                    fontWeight: "bold",
                    lineHeight: { lg: "65px", md: "46px" },
                    marginTop: { xl: 8, md: 0 },
                    fontSize: { lg: 60, md: 40, xs: 30 },
                    textAlign: { md: "left", xs: "center" },
                  }}
                >
                  <span
                    style={{
                      borderBottom: "4px solid black",
                      paddingBottom: "8px",
                    }}
                  >
                    Care
                  </span>
                  er {" "}
                  <strong style={{ color: "#129D5A" }}>@{" "}</strong>InAutomate
                </Typography>

                <Typography
                  paragraph={true}
                  color="grey"
                  lineHeight="30px"
                  align="justify"
                  sx={{
                    textAlign: { md: "left", xs: "center" },
                    fontSize: { lg: 20, md: 18, xs: 16 },
                  }}
                >
                  Wecome to the world of{" "}
                  <span style={{ color: "black" }}>
                    professional opportunities.
                  </span>{" "}
                  We have something for everyone, whether you're a fresh
                  graduate, a seasoned professional looking for a new career, or
                  someone trying to develop in your existing industry.
                </Typography>

                <Box
                  sx={{
                    marginTop: { md: 2, lg: 3, xl: 5 },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <GetButton hello1={"Say Hello"} />
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* <Box mt={5}>
            <HiringCards />
          </Box> */}

          <Box mt={5}>
            <Recruitment />
          </Box>
          {/* 
          <Box>
            <StudentCard />
          </Box> */}
        </Container>
      </Layout>
    </>
  );
};

export default Career;
