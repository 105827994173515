import Img1 from "../asset/images/AcaColl1.png";
import Img2 from "../asset/images/dynamos2.png";
import Img3 from "../asset/images/corporate2.png";
import vision from "../asset/images/visionColl.png";
import echosys from "../asset/images/echosys.png";


export const frontiersCollapse = {
  image: Img1,
  items: [
    {
      title: "Robotics skills",
      des: "From the fundamentals of robotics to advanced applications, our program allows students to have a full theoretical foundation by our dedicated mentor.",
    },
    {
      title: "Software skills",
      des: "Gain expertise in AI-driven software development for efficiency, embracing automation and code generation. Prepare for the future of computing with training in emerging technologies.",
    },
    {
      title: "Business Skills",
      des: "Master essential business skills with our training programs, prepare for success in the modern business landscape by learning to anticipate market trends, leverage emerging technologies, and prioritize innovation.",
    },
  ],
};

export const CollAcaData = {
  image: Img1,
  items: [
    {
      title: "Comprehensive Theoretical Foundation",
      des: "From the fundamentals of robotics to advanced applications, our program allows students to have a full theoretical foundation by our dedicated mentor ",
    },
    {
      title: "Hands-on Practical Learning",
      des: "Hands-on exposure and real-world projects instill practical skills, fostering active contributors to the automated landscape.",
    },
    {
      title: "Industry-Relevant Skill Development",
      des: "Industry-focused training and immersive learning environments ensures graduates are well-prepared for the workforce.",
    },
  ],
};

export const SofwareCollapse = {
  image: Img2,
  items: [
    {
      title: "Comprehensive Software Fundamental",
      des: "Master the fundamentals of software development with our comprehensive training, covering essential concepts, languages, and tools for building robust applications.",
    },
    {
      title: "Hands-On Coding Experience",
      des: "Gain practical skills and confidence to kickstart your career in software engineering, with hands-on exercises and expert guidance.",
    },
    {
      title: "Current Industry Practices",
      des: "Elevate your software skills with training designed for current industry practices, covering agile methodologies, DevOps practices, and modern software development tools.",
    },
  ],
};

export const CorporateCollapse = {
  image: Img3,
  items: [
    {
      title: "Professional Behavioural building",
      des: "By focusing on Professional Behavioural Building, individuals are able to develop key characteristics like professionalism, ethics, and strong communication skills, fostering a positive and thriving workplace culture.",
    },
    {
      title: "Operational Skill Building",
      des: "Operational Skill Building complements individuals with the practical skills needed to excel in their roles and boost efficiency and effectiveness. ",
    },
    {
      title: "Industry Speaks",
      des: "The wisdom from experienced Industry Veterans offers invaluable perspectives, helping individuals effectively navigate obstacles and make the most of opportunities.",
    },
  ],
};


export const synergicLearn = {
  image: echosys,
  items: [
    {
      title: "Forging Pathways to Excellence",
      des: "Through this alliance, students gain access to a rich tapestry of knowledge and practical wisdom, while In-Automate benefits from fresh perspectives and youthful ingenuity. It's akin to unlocking a vault of potential, where mutual growth and advancement flourish.",
    },
    {
      title: "Empowering Future Innovators",
      des: "Delve into the realms of robotics and software development - domains sculpting the contours of tomorrow. By pooling resources, colleges offer students unparalleled access to cutting-edge laboratories, equipment, and expertise curated by In-Automate. Here, learning transcends theoretical bounds, immersing students in hands-on experiences that propel them into the heart of these dynamic fields.",
    },
    {
      title: "Holistic Skill Enrichment",
      des: "Yet, our collaborative endeavor extends beyond technical mastery, emphasizing holistic development. Tailored training modules, crafted in collaboration with colleges and In-Automate, cultivate essential proficiencies essential for success in today's professional landscape. From communication finesse to adept problem-solving and collaborative prowess, these skills form the bedrock of students' future triumphs.",
    },
    {
      title: "Cultivating, Innovation and Creativity",
      des: " Let us not overlook the alchemy sparked by diverse minds converging. Collaboration ignites the flames of innovation and creativity, birthing groundbreaking concepts and solutions. Together, colleges and In-Automate propel the boundaries of possibility, illuminating the path toward a radiant future",
    },
    {
      title: "Continuous Learning and Development",
      des: "Our partnership fosters a culture of lifelong learning, where students and industry professionals alike engage in ongoing skill enhancement. Through workshops, seminars, and mentorship programs, individuals are equipped with the tools and knowledge necessary to navigate the ever-evolving landscape of technology and business",
    },
    {
      title: "Global Perspectives and Industry Insights",
      des: "Leveraging our diverse network, we provide students with exposure to global best practices and industry insights. Collaborative projects and internships offer invaluable real-world experience, preparing individuals to thrive in a globalized marketplace",
    },
    {
      title: "Sustainability and Social Responsibility",
      des: "At the core of our collaboration lies a commitment to sustainability and social responsibility. Through initiatives focused on environmental stewardship and community engagement, we empower students to become conscientious leaders who drive positive change in society",
    },

    
  ],
};

export const accordianHomeCollapse = [
  {
    id: 1,
    title: "Comprehensive Skill Development",
    des: "We believe in providing you with a well-rounded education that  develops both your technical abilities and essential soft skills. You'll be ready for various job opportunities and excel in your field.",
  },
  {
    id: 2,
    title: "Entrepreneurial Thinking",
    des: "We want you to be a leader, not a follower. That's why we encourage creative thinking and leadership skills. You'll be able to come up with innovative ideas and lead confidently in any field.",
  },
  {
    id: 3,
    title: "Global-Mindedness",
    des: "We believe in using the latest technologies ethically and working with professionals worldwide. This will give you a global perspective and prepare you to make a positive impact on the world.",
  },
  {
    id: 4,
    title: "Real-World Readiness",
    des: "Our focus is on practical skills that match what employers need. From early exposure to industry tech to building a professional mindset, we set you up for success in your career journey.You'll be ready to hit the ground running and make a difference in the real world.",
  },
];
