import React from "react";
import VisionImg from "../asset/images/visionImg.png";
import {Box, Grid, Paper, Typography } from "@mui/material";
import quote from "../asset/images/quoteImg.png"
import quote2 from "../asset/images/purpleQuote.png"
import tabquote from "../asset/images/tabquote.png"
import mobilequote from "../asset/images/mobileQuote.png"

const VisionCard = () => {
  return (
    <>
    
    <Typography
                  mb={5}
                  sx={{
                    fontWeight: "bold",
                    lineHeight: { lg: "50px", md: "30px" },
                    marginTop: { xl: 8, md: 0 },
                    fontSize: { lg: 40, md: 25 },
                    textAlign: { md: "left", xs: "center" },
                  }}
                >
                  <span
                    style={{
                      borderBottom: "4px solid black",
                      paddingBottom: "8px",
                    }}
                  >
                    Our{" "}
                  </span>
                  Vision
                </Typography>
      
      <Grid
        container
        sx={{
          margin: "2em 0em",
          alignItems: "center",
        }}
      >
        <Grid item xl={7} lg={7} md={7}  xs={12}>
            <Paper
              elevation={2}
              sx={{
                backgroundColor: "#EFEFFE",
                borderRadius: "45px",
                zIndex:"1",
                left:"10%",
                position:{md:"relative", xs:"none"},
                mb:{md:"0px", xs:"30px"}
              }}
            >

              
              <Typography
                color={"grey"}
                textAlign={"justify"}
                sx={{
                  padding: { xl:"6em 5em 6em 5em", lg:"4.5em 3em 4.5em 3em", md:"3.8em 3em 3.8em 3em", xs:"4em 3em 4em 3em"  },
                  fontSize:{xl:22, lg:20, xs:16 },
                  fontWeight:{xl:"bold", lg:"bold", md:"500",xs:"500"},
                  lineHeight:{xl:"38px", lg:"30px", md:"24px",xs:"none"}
                }}
              >
                <Box sx={{position:"relative", zIndex:1, right:"34px",bottom:"8px", display:{xl:"block",xs:"none"}}}>  
                <img src={quote2} alt="PurpleQuote"/>
                </Box>
                <Box sx={{position:"relative", zIndex:1, right:"24px",display:{xl:"none", md:"block",xs:"none"}}}>  
                <img src={tabquote} alt="PurpleQuote"/>
                </Box>
                <Box sx={{position:"relative", zIndex:1, right:"20px",display:{lg:"none", md:"none",sm:"block",xs:"block"}}}>  
                <img src={mobilequote} alt="PurpleQuote"/>
                </Box>
                At InAutomate, our vision is to revolutionize the way people
                learn and work in the realms of robotics and software. We aspire
                to create a future where technology is not just a tool, but a
                gateway to endless possibilities. Join us as we pave the way for
                innovation and transformation
              </Typography>
            </Paper>
        </Grid>

        <Grid item xl={5} lg={5} md={5} xs={12}>
          <img src={VisionImg} width="100%" alt="InAutomate Image" />
        </Grid>
      </Grid>
    </>
  );
};

export default VisionCard;
